<template>
  <div class="side-bar__body"
       :class="{'iphone' : iphoneDetect(),
       'side-bar-admin' : checkImpersonate,
       'side-bar-user' : checkImpersonate
       }"
  >
    <!--<div class="side-bar__content">-->


    <!--<vue-custom-scrollbar-->
    <!--class="side-bar__content-scrollbar"-->
    <!--:settings="settings"-->
    <!--&gt;-->

    <div class="side-bar__head">
      <router-link :to="'/#'" class="side-bar__head-ico">
        <SkladUSALogo/>
      </router-link>

    </div>
    <div class="side-bar__user" v-bind:class="{admin: checkImpersonate}">
          <span @click="goToProfile" class="side-bar__user-ico cursor-pointer">
            <img v-if="$store.getters.getAvatarSmall.length > 0" :src="$store.getters.getAvatarSmall" alt="user-ico">
            <img v-else src="/img/profile-group/profile-photo-default.svg" alt="user-ico">
            <div class="side-bar__user-indicator side-bar__user-indicator--online"></div>
          </span>
      <div class="side-bar__user-info">
        <template v-if="$store.getters.getUserProfile.user_personal_contact">
              <span class="side-bar__user-name btn-style"
                    @click="goToProfile"
              >
                {{ $store.getters.getUserProfile.user_personal_contact.user_full_name }}
              </span>
          <div class="side-bar__user-email">
            {{ $store.getters.getUserProfile.user_personal_contact.email }}
          </div>
        </template>
      </div>
    </div>

    <!--<div class="side-bar__login-as" v-if="checkImpersonate">-->
    <!--<div class="">-->
    <!--<MainButton-->
    <!--class="secondary secondary-brown wfc"-->
    <!--:value="'Back to Admin'"-->
    <!--@click.native="resetImpersonate"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->

    <!--    <SidebarSearch />-->
    <div class="side-bar__content-scrollbar-inner">


      <MenuScrollContainer>
        <template slot="content">
          <div class="side-bar__menu" v-if="isUserHasEasyOrder">
            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.dashboard )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <DashboardIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"
                >
                  {{ $t('menu_dashboard.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"

                 v-bind:class="{active :
                    checkActiveRoute($store.getters.GET_PATHS.easyOrders) ||
                    checkActiveRoute($store.getters.GET_PATHS.ordersShipmentWarehouse) ||
                    checkActiveRoute($store.getters.GET_PATHS.ordersConsolidation),
                   'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
            >
              <span class="admin-edit" @click="editTranslate(ordersAndDispatchesTranslates)"
                    v-if="!isMobileFunc()"></span>

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <OrdersDispatchesIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.easyOrders) || checkActiveRoute($store.getters.GET_PATHS.ordersConsolidation) || checkActiveRoute($store.getters.GET_PATHS.ordersShipmentWarehouse)}"
                >
                  {{ $t('menu_easyOrders.localization_value.value') }}
                </div>

                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in easyOrders.links" :key="index"
                      :class="{'active-consolidation' : item.linkPath === $store.getters.GET_PATHS.ordersConsolidation && (checkActiveRoute($store.getters.GET_PATHS.ordersConsolidation) || checkActiveRoute($store.getters.GET_PATHS.ordersShipmentWarehouse))}"
                      :item="item"
                  />
                </div>
              </div>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="_.has(currentPermissions, PERMISSIONS.ORDER_EXTERNAL_GENERAL)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.importOrders )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <OrdersDispatchesIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.importOrders"
                >
                  {{ $t('menu_importOrders.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="_.has(currentPermissions, PERMISSIONS.INVOICES_GENERAL)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.financeInvoices )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <FinanceIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.financeInvoices"
                >
                  {{ $t('menu_invoices.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

<!--                 v-if="_.has(currentPermissions, PERMISSIONS.INVOICES_GENERAL)"-->
            <div class="side-bar__menu-item admin-edit-item"
                 v-if="isPoland"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.IE559Report )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <FinanceIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.IE559Report"
                >
                  {{ $t('menu_IE559Report.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(['menu_IE559Report'])"></span>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="_.has(currentPermissions, PERMISSIONS.PRODUCT_GENERAL)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.products )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <WarehouseIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.products"
                >
                  {{ $t('menu_products.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(problemsList.links)"
                 v-bind:class="{
                 'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate,
                  active : checkActiveRoute('problems')
               }">
              <span class="admin-edit" @click="editTranslate(problemsTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ProblemsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('problems')}">
                  {{ $t('menu_problems.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in problemsList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.downloadPlugins )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ProblemsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.downloadPlugins"
                >
                  {{ $t('menu_modules.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="_.has(currentPermissions, PERMISSIONS.DELIVERY_STATISTIC_GENERAL)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.statisticsDeliveryStatistics )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <StatisticsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.statisticsDeliveryStatistics"
                >
                  {{ $t('menu_deliveryStatistics.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.notificationsList )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.notificationsList"
                >
                  {{ $t('menu_notifications.localization_value.value') }}
                </router-link>

                <div class="side-bar__menu-counter" v-if="$store.getters.getNotificationsCount > 0">
                  {{ $store.getters.getNotificationsCount }}
                </div>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-if="_.has(currentPermissions, PERMISSIONS.SHOPS_GENERAL)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.mainSettingsShops )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <SettingsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.mainSettingsShops"
                >
                  {{ $t('menu_shops.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

          </div>

          <div v-else class="side-bar__menu">

            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.dashboard )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <DashboardIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.dashboard"
                >
                  {{ $t('menu_dashboard.localization_value.value') }}
                </router-link>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(ordersAndDispatchesList.links)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.ordersAndDispatches ),
                   'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
            >
              <span class="admin-edit" @click="editTranslate(ordersAndDispatchesTranslates)"
                    v-if="!isMobileFunc()"></span>

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <OrdersDispatchesIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.ordersAndDispatches + '/')}"
                >
                  {{ $t('menu_ordersDispatches.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in ordersAndDispatchesList.links" :key="index"
                      :item="item"

                  />
                </div>
              </div>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(financeList.links)"
                 v-bind:class="{active : checkActiveRoute('/transactions') || checkActiveRoute('/finance'),
             'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
            >
              <span class="admin-edit" @click="editTranslate(financeTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <FinanceIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('/transactions') || checkActiveRoute('/finance')}"
                >
                  {{ $t('menu_finance.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in financeList.links" :key="index + '' + onChangePermissions"
                      :item="item"
                  />
                  <template>
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link class="side-bar__menu-submenu-item-name"-->
                    <!--:to="$store.getters.GET_PATHS.cusPayments"-->
                    <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.cusPayments)}">-->
                    <!--{{ $t('menu_customersPayments.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link class="side-bar__menu-submenu-item-name"-->
                    <!--:to="$store.getters.GET_PATHS.financeUnidentifiedPayments"-->
                    <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.financeUnidentifiedPayments)}">-->
                    <!--{{ $t('menu_unidentifiedPayments.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link :to="$store.getters.GET_PATHS.financeUkrTreasuresLink" class="side-bar__menu-submenu-item-name">-->
                    <!--&lt;!&ndash;{{ $t('menu_ukrainian-treasures.localization_value.value') }}&ndash;&gt;-->
                    <!--UkrainianTreasures.com Listing-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link :to="'/#'" class="side-bar__menu-submenu-item-name">-->
                    <!--{{ $t('menu_paymentListings.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link :to="'/#'" class="side-bar__menu-submenu-item-name">-->
                    <!--{{ $t('menu_etsyListings.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link class="side-bar__menu-submenu-item-name"-->
                    <!--:to="$store.getters.GET_PATHS.financeRequestFunds"-->
                    <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.financeRequestFunds)}">-->
                    <!--{{ $t('menu_requestFunds.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link class="side-bar__menu-submenu-item-name"-->
                    <!--:to="$store.getters.GET_PATHS.financeRequestReturn"-->
                    <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.financeRequestReturn)}">-->
                    <!--{{ $t('menu_requestReturn.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link :to="'/#'" class="side-bar__menu-submenu-item-name">-->
                    <!--{{ $t('menu_invoices.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link :to="$store.getters.GET_PATHS.financeMyPayments" class="side-bar__menu-submenu-item-name">-->
                    <!--{{ $t('menu_myPayments.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link :to="'/#'" class="side-bar__menu-submenu-item-name">-->
                    <!--{{ $t('menu_topUpBalance.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                    <!--<div class="side-bar__menu-submenu-item">-->
                    <!--<router-link class="side-bar__menu-submenu-item-name"-->
                    <!--:to="$store.getters.GET_PATHS.financePayPalCases"-->
                    <!--v-bind:class="{'router-link-active' : checkActiveRoute($store.getters.GET_PATHS.financePayPalCases)}">-->
                    <!--{{ $t('menu_payPalCases.localization_value.value') }}-->
                    <!--</router-link>-->
                    <!--</div>-->
                  </template>
                </div>
              </div>
            </div>
            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(warehouseList.links)"
                 v-bind:class="{active : checkActiveRoute('warehouse'),
             'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}">
              <span class="admin-edit" @click="editTranslate(warehouseTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <WarehouseIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('warehouse')}">
                  {{ $t('menu_warehouse.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in warehouseList.links" :key="index"
                      :item="item"
                  />

                </div>
              </div>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(problemsList.links)"
                 v-bind:class="{
                 'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate,
                  active : checkActiveRoute('problems')
               }">
              <span class="admin-edit" @click="editTranslate(problemsTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ProblemsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute('problems')}">
                  {{ $t('menu_problems.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in problemsList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>

            <!--          <div class="side-bar__menu-item"-->
            <!--               v-bind:class="{-->
            <!--                  active : checkActiveRoute('income-and-expenses'),-->
            <!--                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate-->
            <!--                }">-->
            <!--            <span class="admin-edit" @click="editTranslate(incomeTranslates)" v-if="!isMobileFunc()"></span>-->
            <!--            <div class="side-bar__menu-item-ico">-->
            <!--              <div class="side-bar__menu-item-ico-i">-->
            <!--                <IncomeExpensesIco/>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="side-bar__menu-item-content">-->
            <!--              <div class="side-bar__menu-item-name accordItemTitlesJs"-->
            <!--                   v-bind:class="{open : checkActiveRoute('income-and-expenses')}">-->
            <!--                {{ $t('menu_incomeExpenses.localization_value.value') }}-->
            <!--              </div>-->
            <!--              <div class="side-bar__menu-submenu">-->

            <!--                <MenuItem-->
            <!--                        v-for="(item, index) in incomeExpressList.links" :key="index"-->
            <!--                        :item="item"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->

<!--            <div class="side-bar__menu-item"-->
<!--                 v-if="checkVisibleGroupMenu(reportsList.links)"-->
<!--                 v-bind:class="{-->
<!--                  active : checkActiveRoute('/reports/'),-->
<!--                'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate-->
<!--              }">-->

<!--              <div class="side-bar__menu-item-ico">-->
<!--                <div class="side-bar__menu-item-ico-i">-->
<!--                  <ReportIco/>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="side-bar__menu-item-content">-->
<!--                <div class="side-bar__menu-item-name accordItemTitlesJs"-->
<!--                     v-bind:class="{open : checkActiveRoute('/reports/')}">-->
<!--                  Reports-->
<!--                </div>-->

<!--                <div class="side-bar__menu-submenu">-->

<!--                  <MenuItem-->
<!--                      v-for="(item, index) in reportsList.links" :key="index"-->
<!--                      :item="item"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(reportsTranslates)"></span>-->
<!--            </div>-->

<!--                 v-if="checkVisibleGroupMenu(heartysan.links)"-->



            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(settingsList.links)"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.mainSettings ),
             'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}">
              <span class="admin-edit" @click="editTranslate(settingsTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <SettingsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{open : checkActiveRoute($store.getters.GET_PATHS.mainSettings + '/')}">
                  {{ $t('menu_settings.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">
                  <MenuItem
                      v-for="(item, index) in settingsList.links" :key="index"
                      :item="item"
                      @goToPersonalAccount="goToPersonalAccount"
                  />
                </div>
              </div>
            </div>

            <div class="side-bar__menu-item admin-edit-item"
                 v-bind:class="{active : checkActiveRoute($store.getters.GET_PATHS.notificationsList )}"
            >
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <router-link class="side-bar__menu-item-name" :to="$store.getters.GET_PATHS.notificationsList"
                >
                  {{ $t('menu_notifications.localization_value.value') }}
                </router-link>

                <div class="side-bar__menu-counter" v-if="$store.getters.getNotificationsCount > 0">
                  {{ $store.getters.getNotificationsCount }}
                </div>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(dashboardTranslates)"></span>
            </div>

            <div class="side-bar__menu-item "
                 v-if="checkVisibleGroupMenu(statisticsList.links)"
                 v-bind:class="{
                   active : checkActiveRoute($store.getters.GET_PATHS.statisticsDeliveryStatistics) ||
                      checkActiveRoute($store.getters.GET_PATHS.graphicsStatistic),
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}">
              <span class="admin-edit" @click="editTranslate(statisticTranslates)" v-if="!isMobileFunc()"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <StatisticsIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="accordItemTitlesJs side-bar__menu-item-name"
                     v-bind:class="{
                      open : checkActiveRoute($store.getters.GET_PATHS.statisticsDeliveryStatistics) ||
                          checkActiveRoute($store.getters.GET_PATHS.graphicsStatistic)}">
                  {{ $t('menu_statistics.localization_value.value') }}
                </div>
                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in statisticsList.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>

            <div class="side-bar__menu-item"
                 v-if="!isSubUser && !isCurrentUserRoleUser"
                 v-bind:class="{
                  active : checkActiveRoute('/heartysan/'),
                'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate
              }">

              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content"
                   @click="activeChatEvent = false">
                <div class="side-bar__menu-item-name accordItemTitlesJs"
                     v-bind:class="{open : checkActiveRoute('/heartysan/')}">
                  <div class="heartisan-menu-style">
                    {{ $t('menu_heartysan.localization_value.value') }}
                    <span v-if="activeChatEvent" class="heartisan-menu-style-active">
                      <StatusIcoBtn
                          :type="'no-info'"
                      />
                    </span>
                  </div>
                </div>

                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in heartysan.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(heartysanTranslates)"></span>
            </div>

            <div class="side-bar__menu-item"
                 v-if="checkVisibleGroupMenu(marketplace.links)"
                 v-bind:class="{
                  active : checkActiveRoute('/marketplace/'),
                'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate
              }">
              <span class="admin-edit" v-if="!isMobileFunc()" @click="editTranslate(marketplaceTranslates)"></span>
              <div class="side-bar__menu-item-ico">
                <div class="side-bar__menu-item-ico-i">
                  <ReportIco/>
                </div>
              </div>
              <div class="side-bar__menu-item-content">
                <div class="side-bar__menu-item-name accordItemTitlesJs"
                     v-bind:class="{open : checkActiveRoute('/marketplace/')}">
                  {{ $t('menu_marketplace.localization_value.value') }}
                </div>

                <div class="side-bar__menu-submenu">

                  <MenuItem
                      v-for="(item, index) in marketplace.links" :key="index"
                      :item="item"
                  />
                </div>
              </div>
            </div>

          </div>
        </template>
      </MenuScrollContainer>

    </div>
    <!--</vue-custom-scrollbar>-->

    <div class="side-bar__login-as" v-if="checkImpersonate">
      <div class="">
        <MainButton
            class="secondary secondary-brown wfc"
            :value="'Back to Admin'"
            v-bind:class="{'disabled-btn' : $store.getters.getImpersonateButton}"
            @click.native="resetImpersonate"
        />
      </div>
    </div>

    <div class="side-bar__bottom"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
    >
      <span class="admin-edit" v-if="!isMobileFunc()"
            @click="editTranslate(['menu_contactAndWarehouses', 'menu_helpAndSupport',])"></span>
      <div
          @click="showAddressPopupHandler"
          class="side-bar__bottom-btn site-link">
        <div class="side-bar__bottom-btn-ico">
          <AddressIco style="width: 18px;"/>
        </div>
        <div class="side-bar__bottom-btn-txt">
          {{ $t('menu_contactAndWarehouses.localization_value.value') }}
        </div>
      </div>
      <a :href="supportLink"
         target="_blank" rel="nofollow" class="side-bar__bottom-btn">
        <div class="side-bar__bottom-btn-ico">
          <HelpIco/>
        </div>
        <div class="side-bar__bottom-btn-txt">
          {{ $t('menu_helpAndSupport.localization_value.value') }}
        </div>
      </a>
      <div class="side-bar__bottom-link"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
      >
        <span class="side-bar__bottom-link--big" style="color: #BBAD9C;">
            <span class="admin-edit"
                  @click="editTranslate(['menu_confidencial', 'menu_generalTerms', 'menu_and'])"></span>
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/privacy-policy/`" class="site-link" target="_blank">
                {{ $t('menu_confidencial.localization_value.value') }}&nbsp;&nbsp;
              </a>
              {{ $t('menu_and.localization_value.value') }}
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/user-agreement/`" class="site-link" target="_blank">
                {{ $t('menu_generalTerms.localization_value.value') }}
              </a>
          <!--{{ $t('menu_showOfficesAddresses.localization_value.value') }}-->
        </span>
        <span class="side-bar__bottom-link--small" style="color: #BBAD9C; transform: translateY(-18px);">
            <span class="admin-edit"
                  @click="editTranslate(['menu_confidencial', 'menu_generalTerms', 'menu_and'])"></span>
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/privacy-policy/`" class="site-link" target="_blank">
                {{ $t('menu_confidencial.localization_value.value') }}&nbsp;&nbsp;
              </a>
              {{ $t('menu_and.localization_value.value') }}
              <a :href="`https://skladusa.com/${$store.getters.GET_LANG}/user-agreement/`" class="site-link" target="_blank">
                {{ $t('menu_generalTerms.localization_value.value') }}
              </a>
          <!--{{ $t('menu_addresses.localization_value.value') }}-->
        </span>
      </div>
      <div style="margin-top: 3px;" class="side-bar__bottom-link"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate}"
      >
            <span class="side-bar__bottom-link--big site-link">
              <span class="admin-edit" v-if="!isMobileFunc()"
                    @click="editTranslate(['common_byAsabix'])"></span>
              <a href="https://asabix.com/" target="_blank" class="site-link">
                {{ $t('common_byAsabix.localization_value.value') }} &nbsp;
              </a>&nbsp;
            </span>
        <span class="side-bar__bottom-link--small site-link">
              <span class="admin-edit" v-if="!isMobileFunc()"
                    @click="editTranslate(['common_byAsabix'])"></span>
              <a href="https://asabix.com/" target="_blank" class="site-link">
                {{ $t('common_byAsabix.localization_value.value') }} &nbsp;
              </a>&nbsp;
        </span>
      </div>

    </div>


    <!--</div>-->
  </div>
</template>

<script>
import SkladUSALogo from '../../../../../public/img/sidebar-group/SkladUSALogo.svg?inline'
import OrdersDispatchesIco from '../../../../../public/img/sidebar-group/OrdersDispatchesIco.svg?inline'
import FinanceIco from '../../../../../public/img/sidebar-group/FinanceIco.svg?inline'
import WarehouseIco from '../../../../../public/img/sidebar-group/WarehouseIco.svg?inline'
import ProblemsIco from '../../../../../public/img/sidebar-group/ProblemsIco.svg?inline'
// import IncomeExpensesIco from '../../../../../public/img/sidebar-group/IncomeExpensesIco.svg?inline'
import ReportIco from '../../../../../public/img/sidebar-group/ReportIco.svg?inline'
import SettingsIco from '../../../../../public/img/sidebar-group/SettingsIco.svg?inline'
import StatisticsIco from '../../../../../public/img/sidebar-group/StatisticsIco.svg?inline'
import HelpIco from '../../../../../public/img/sidebar-group/HelpIco.svg?inline'
import AddressIco from '../../../../../public/img/sidebar-group/addressIco.svg?inline'
import DashboardIco from '../../../../../public/img/sidebar-group/DashboardIco.svg?inline'

// import vueCustomScrollbar from 'vue-custom-scrollbar';
// import "vue-custom-scrollbar/dist/vueScrollbar.css"
import {ADMIN_ROUTE_STORAGE, EN_LANG, RU_LANG, UA_LANG} from "../../../../staticData/staticVariables";
import {checkAccess} from "../../../../mixins/userCredentionalMixins/userCredentional";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import {GetMenuList} from "./menuCounters";
import {GetMenuTranslationsList} from "./menuTranslationsList.js";
import MenuItem from "../components/MenuItem";
import {mixinDetictingMobile} from "../../../../mixins/mobileFunctions";
import {munuFunc} from "../../../../mixins/menuMixins/menuFunc";
import MenuScrollContainer from "../../../coreComponents/MenuScrollContainer/MenuScrollContainer";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
import {PERMISSIONS} from "@/staticData/permissionsStatic";
// import SidebarSearch from "../../../coreComponents/SidebarSearch/SidebarSearch";


export default {
  name: "SideBar",

  components: {
    StatusIcoBtn,
    // SidebarSearch,
    SkladUSALogo,
    OrdersDispatchesIco,
    FinanceIco,
    WarehouseIco,
    ProblemsIco,
    // IncomeExpensesIco,
    ReportIco,
    SettingsIco,
    StatisticsIco,
    HelpIco,
    AddressIco,

    // vueCustomScrollbar,
    MainButton,
    MenuItem,
    MenuScrollContainer,
    DashboardIco,
  },

  props: [],

  mixins: [checkAccess, mixinDetictingMobile, munuFunc],

  computed: {
    checkImpersonate() {
      return this.localStorage?.parentUserId
    },

    lang: function () {
      return this.$store.getters.GET_LANG
    },

    forbiddenGoodsCount() {
      return this.$store.getters.GET_COMMON_AUTHORIZED?.forbidden_payment_transaction_count
    },

    balanceOutputCount() {
      return this.$store.getters.GET_COMMON_AUTHORIZED?.balance_output_count
    },

    heartysanChatNewMessagesCount() {
      return this.$store.getters.GET_COMMON_AUTHORIZED?.count_hearty_chat_new_messages
    },

    getChatEvents() {
      return this.$store.getters.getHeartysanChatEvents
    },

    onChangePermissions() {
      return this.$store.getters.getChangeUserRights
    },
  },

  watch: {
    // onChangePermissions(newVal) {
    //   console.log(1211222);
    //   console.log(newVal);
    // },
    lang: function (newCount) {
      this.checkLang(newCount)
    },

    forbiddenGoodsCount(newVal) {
      this.setMenuForbiddenGoodsCounts(newVal)
    },

    balanceOutputCount(newVal) {
      this.setMenuBalanceOutputCounts(newVal)
    },

    heartysanChatNewMessagesCount(newVal) {
      this.setMenuHeartysanChatNewMessagesCount(newVal)

      if(newVal > 0 && this.$route.fullPath.indexOf('heartysan') === -1){
        this.activeChatEvent = true
      }
    },

    getChatEvents() {
      let authorizedData = this.$store.getters.GET_COMMON_AUTHORIZED
      authorizedData.count_hearty_chat_new_messages = authorizedData.count_hearty_chat_new_messages + 1
      this.$store.commit('SET_COMMON_AUTHORIZED', {COMMON_AUTHORIZED: authorizedData})

      if(this.$route.fullPath.indexOf('heartysan') === -1){
        this.activeChatEvent = true
      }
    },
  },

  data() {
    return {
      // settings: {
      //   suppressScrollY: false,
      //   suppressScrollX: true,
      //   wheelPropagation: false,
      //   wheelSpeed: 0.5,
      // },

      PERMISSIONS: PERMISSIONS,

      activeChatEvent: false,

      localStorage: localStorage,

      windowWidth: '',
      showAddress: false,
      sideBarSearch: '',

      supportLink: '',
      EN_LANG: EN_LANG,
      RU_LANG: RU_LANG,
      UA_LANG: UA_LANG,

      ordersAndDispatchesList: new GetMenuList()
          .ORDER_DISPATCHES(this),
      easyOrders: new GetMenuList()
          .EASY_ORDERS(this),
      financeList: new GetMenuList()
          .FINANCE(this),
      warehouseList: new GetMenuList()
          .WAREHOUSE(this),
      problemsList: new GetMenuList()
          .PROBLEMS(this),
      incomeExpressList: new GetMenuList()
          .INCOME_EXPRESS(this),
      // reportsList: new GetMenuList()
      //     .REPORTS(this),
      heartysan: new GetMenuList()
          .HEARTYSAN(this),
      settingsList: new GetMenuList()
          .SETTINGS(this),
      statisticsList: new GetMenuList()
          .STATISTICS(this),
      marketplace: new GetMenuList()
          .MARKETPLACE(this),


      dashboardTranslates: new GetMenuTranslationsList()
          .DASHBOARD(),
      ordersAndDispatchesTranslates: new GetMenuTranslationsList()
          .ORDER_AND_DISPATHES(),
      financeTranslates: new GetMenuTranslationsList()
          .FINANCE(),
      warehouseTranslates: new GetMenuTranslationsList()
          .WAREHOUSE(),
      problemsTranslates: new GetMenuTranslationsList()
          .PROBLEMS(),
      incomeTranslates: new GetMenuTranslationsList()
          .INCOME_EXPRESS(),
      // reportsTranslates: new GetMenuTranslationsList()
      //     .REPORTS(),
      heartysanTranslates: new GetMenuTranslationsList()
          .HEARTYSAN(),
      settingsTranslates: new GetMenuTranslationsList()
          .SETTINGS(),
      statisticTranslates: new GetMenuTranslationsList()
          .STATISTICS(),
      marketplaceTranslates: new GetMenuTranslationsList()
          .MARKETPLACE(),

    }
  },

  mounted() {
    this.checkLang(this.$store.getters.GET_LANG)

    this.initMenu()

    this.setMenuForbiddenGoodsCounts(this.$store.getters.GET_COMMON_AUTHORIZED?.forbidden_payment_transaction_count)
  },


  methods: {


    setMenuForbiddenGoodsCounts(value) {
      let item = this._.find(this.financeList.links, {translation: 'menu_forbiddenGoods'})
      item.count = value
    },

    setMenuBalanceOutputCounts(value) {
      let item = this._.find(this.financeList.links, {translation: 'menu_requestFunds'})
      item.count = value
    },

    setMenuHeartysanChatNewMessagesCount(value) {
      let item = this._.find(this.heartysan.links, {translation: 'menu_heartysanChat'})
      item.count = value
    },

    checkLang(val) {
      switch (val) {
        case EN_LANG:
          this.supportLink = 'https://skladusa.freshdesk.com/en/support/home'
          break
        case RU_LANG:
          this.supportLink = 'https://skladusa.freshdesk.com/ru-RU/support/home'
          break
        case UA_LANG:
          this.supportLink = 'https://skladusa.freshdesk.com/uk/support/home'
          break
        default:
          break
      }
    },



    resetImpersonate() {
      let data = {
        impersonateId: localStorage.getItem('parentUserId')
      }

      this.$store.dispatch('resetImpersonate', data).then((response) => {
        if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          // Add to localstorage variable for checking admin routes
          localStorage.setItem(ADMIN_ROUTE_STORAGE, 'Y')
          location.href = this.$store.getters.GET_PATHS.dashboardAdmin
          // End add to localstorage variable for checking admin routes
        } else {
          this.$store.dispatch('logOut')
          // location.reload()
          // console.log(23423);
        }
      })
    },

    checkActiveRoute(path) {
      let routeMatchedPath = this._.last(this.$route.matched)?.path
      if(routeMatchedPath) {
        return routeMatchedPath.indexOf(path) > -1 ? true : false
      }
      return false
      // return this.$route.path.indexOf(path) > -1 ? true : false;
    },

    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },

    showAddressPopupHandler() {
      this.$emit('openAddressPopup')
    },


    // resizeWindowEvent(){
    //   this.$forceUpdate();
    // },

  },
  //
  // created() {
  //   window.addEventListener("resize", this.resizeWindowEvent);
  // },
  //
  // destroyed() {
  //   window.removeEventListener("resize", this.resizeWindowEvent);
  // },


}

</script>

<style lang="scss">
@import "./SideBar";

.heartisan-menu-style{
  position: relative;
}

.heartisan-menu-style-active{
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  margin-right: -16px;
  margin-top: -9px;
}

</style>

<style scoped>
  .active-consolidation >>> a {
    opacity: 1;
  }
</style>